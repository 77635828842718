<template>
  <div class="card">
    <CarouselComponent :bulletinboard="bulletinboard" />
    <div class="card-body">
      <h5 class="card-title">{{ bulletinboard.title }}</h5>
      <div
        class="card-text mb-3"
        :class="[show ? '' : 'card-text-obscure']"
        v-html="bulletinboard.description"
      ></div>
    </div>
    <div class="card-footer">
      <a
        :href="
          '/news/' + bulletinboard._id + '/' + createSlug(bulletinboard.title)
        "
        class="bulletin"
        >Lees meer...</a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CarouselComponent from "@/components/CarouselComponent.vue";

export default {
  name: "BulletinCard",
  props: {
    bulletinboard: Object,
  },
  components: { CarouselComponent },
  data() {
    return {
      show: false,
      message: null,
      email: null,
      bulletinformsend: null,
    };
  },
  computed: {
    isDisabled() {
      if (this.message && this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  created() {},
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    postToBulletinInbox() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          cockpiturl + "/inbox/submit/a7cc49ddffa54fd22e068608843c571f6a3eb01c",
          {
            data: {
              subject: this.bulletinboard.title,
              email: this.email,
              message: this.message,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.bulletinformsend = response.data;
        })
        .catch((err) => console.log(err));
    },
    createSlug(text) {
      return text
        .toString() // Convert to string
        .toLowerCase() // Convert to lowercase
        .trim() // Trim whitespace from both ends
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-"); // Replace multiple hyphens with a single hyphen
    },
  },
};
</script>

<style scoped>
.card-body {
  min-height: 180px;
}
.card-img-top {
  width: 100%;
  /* height: 15vw; */
  object-fit: cover;
}
.card-text {
  margin-bottom: 0px !important;
  white-space: pre-wrap;
}
.card-text-obscure {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  background: -webkit-linear-gradient(#333, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bulletin {
  color: #263540;
  text-decoration: none;
  cursor: pointer;
}
</style>
