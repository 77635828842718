import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import BulletinView from "../views/BulletinView.vue";
import BulletinsView from "../views/BulletinsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "news",
    component: BulletinsView,
  },
  {
    path: "/news/:id/:slug/",
    name: "newsitem",
    component: BulletinView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Using the variables to satisfy ESLint, even if not directly utilized
    if (to || from || savedPosition) {
      // This block is intentionally left blank as the variables are not needed here
    }
    return { top: 0 }; // Scrolls to the top of the page on route change
  },
});

export default router;
