<template>
  <section id="herosection" class="hero-section" v-if="herosection">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-md-4 overflow-hidden d-block d-sm-none">
          <img class="ster" src="@/assets/CirkelPijl.png" width="100%" />
        </div>
        <div class="col-md-8 place-on-top">
          <div
            class="h-100 pt-5 pb-5 place-on-top"
            v-html="herosection.header"
          ></div>
          <a
            class="btn btn-secondary btn-lg p-2"
            v-if="herosection.btn_url && herosection.btn_text"
            :href="herosection.btn_url"
          >
            <strong class="p-2">{{ herosection.btn_text }}</strong>
          </a>
        </div>
        <div class="col-md-4 overflow-hidden d-none d-sm-block">
          <img class="ster" src="@/assets/CirkelPijl.png" width="100%" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "HeroSection",
  components: {},
  data() {
    return {
      herosection: null,
      backgroundColor: "#000",
    };
  },
  created() {
    this.fetchHeroSection();
  },
  methods: {
    fetchHeroSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/herosection", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.herosection = response.data;
          if (this.herosection.section_color) {
            this.backgroundColor = this.herosection.section_color;
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: v-bind("backgroundColor");
  color: #f5f3ea;
}
.hero-container {
  padding: 2em;
}
.cirkelpijl {
  position: absolute;
  top: 150px;
  z-index: 500;
  /* opacity: 0.75; */
  float: right;
}
.place-on-top {
  z-index: 1000;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
