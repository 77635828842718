<template>
  <section id="aboutsection" class="hero-section" v-if="aboutsection">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-md-8 align-middle place-on-top">
          <div class="h-100 pt-3" v-html="aboutsection.description"></div>
        </div>
        <div class="col-md-4">
          <div class="ratio ratio-4x3">
            <img
              class="image ratio-4x3"
              :src="getImageUrl(aboutsection.image.path)"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "AboutSection",
  components: {},
  data() {
    return {
      aboutsection: null,
    };
  },
  created() {
    this.fetchAboutSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchAboutSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/aboutsection", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.aboutsection = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #fff;
  color: #263540;
}
.hero-container {
  padding: 1em;
}
.place-on-top {
  z-index: 1000;
}
.image {
  border-radius: 10px;
}
</style>
