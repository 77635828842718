<template lang="html">
  <section id="navbar" class="navbar-section">
    <div class="container">
      <nav
        class="navbar navbar-expand-lg fixed-top navbar-border border-bottom"
      >
        <div class="container p-0">
          <a class="navbar-brand" href="/">
            <img
              src="../assets/logo.svg"
              alt="Bootstrap"
              width="141"
              height="70"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto">
              <!-- <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li> -->
            </ul>
            <ul class="navbar-nav">
              <li class="nav-item white-a">
                <a class="nav-link me-2" href="/#aboutsection">Over Winnen!</a>
              </li>
              <li class="nav-item white-a">
                <a class="nav-link me-2" href="/news">Nieuws</a>
              </li>
              <li class="nav-item white-a">
                <a class="nav-link me-2" href="/#partnersection">Doe mee</a>
              </li>
              <li class="nav-item">
                <a class="btn btn-primary text-white" href="/#contactsection"
                  >Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="css">
body {
  padding-top: 95px;
}
.navbar {
  background-color: #f5f3ea;
  filter: drop-shadow(0px 1px 1px #263540);
}
.navbar-border {
  border-bottom: #f34 !important;
  /* border-bottom: #263540 !important; */
  border-width: 1px;
}
.white-a a {
  color: #263540 !important;
}
</style>
