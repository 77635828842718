<template>
  <section id="partnersection" class="hero-section" v-if="partnersSection">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-12 align-middle">
          <h2 class="pb-2">{{ partnersSection.title }}</h2>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-md-6 g-4">
        <div
          class="col text-center"
          v-for="partner in partnersSection.partner"
          v-bind:key="partner"
        >
          <a
            :href="partner.url"
            target="_blank"
            class="align-middle h-100 in-middle"
          >
            <img
              class="image"
              :src="getImageUrl(partner.image.path)"
              width="125"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnersSection",
  components: {},
  data() {
    return {
      partnersSection: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/partners", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.partnersSection = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #f5f3ea;
  color: #263540;
}
.hero-container {
  padding: 2em;
}
.in-middle {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}
.image {
  filter: grayscale(1);
  /* filter: sepia(100%) hue-rotate(150deg) brightness(80%) saturate(420%); */
  /* opacity: 0.7; */
}
.image:hover {
  filter: none !important;
  opacity: 1 !important;
}
</style>
