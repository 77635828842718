<template>
  <BulletinSection />
</template>

<script>
import BulletinSection from "@/components/BulletinSection.vue";

export default {
  name: "BulletinsView",
  components: { BulletinSection },
  created() {},
  methods: {},
};
</script>

<style></style>
