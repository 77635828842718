<template>
  <span id="top-link-block" :class="{ hidden: !isVisible }" class="on-top">
    <a href="#top" class="well well-sm" @click.prevent="scrollToTop">
      <i class="bi bi-chevron-up"></i>
    </a>
  </span>
</template>

<script>
export default {
  name: "BackToTop",
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 100;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
#top-link-block {
  position: fixed;
  bottom: 30px;
  right: 30px;
}
.well {
  padding: 20px;
  background-color: #db7257;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}
.on-top {
  z-index: 1000;
}
</style>
