<template>
  <section id="bulletinsection" class="hero-section" v-if="bulletinboard">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-12 align-middle">
          <h2 class="pb-2">{{ bulletinboard.title }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <CarouselComponent :bulletinboard="bulletinboard" :rounded="true" />
        </div>
        <div class="col-6">
          <div v-html="bulletinboard.description"></div>
          <br />
          <strong class="text-secondary">Meer Info</strong>
          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="E-mailadres"
            />
          </div>
          <div class="mb-3">
            <textarea
              v-model="message"
              class="form-control form-control-sm"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Jouw vraag"
            ></textarea>
          </div>
          <div class="d-grid gap-2">
            <button
              :disabled="isDisabled == true"
              class="btn btn-sm btn-secondary"
              type="button"
              @click="postToBulletinInbox()"
            >
              Verstuur
            </button>
          </div>
        </div>
        <div v-if="bulletinformsend && show">
          <h5 class="card-title text-secondary">
            Bedankt! We komen hier snel op terug.
          </h5>
        </div>
      </div>
    </div>
  </section>
  <!-- {{ bulletinboard }} -->
</template>

<script>
import axios from "axios";
import CarouselComponent from "@/components/CarouselComponent.vue";

export default {
  name: "BulletinSection",
  components: { CarouselComponent },

  data() {
    return {
      bulletinboard: null,
    };
  },
  created() {
    this.fetchBulletinItem();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchBulletinItem() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      let id = this.$route.params.id;

      axios
        .get(cockpiturl + "/content/item/bulletinboard", {
          headers: {
            "api-key": token,
          },
          params: {
            filter: { _id: id },
          },
        })
        .then((response) => {
          console.log(response.data);
          this.bulletinboard = response.data;
        })
        .catch((err) => console.log(err));
    },
    postToBulletinInbox() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          cockpiturl + "/inbox/submit/a7cc49ddffa54fd22e068608843c571f6a3eb01c",
          {
            data: {
              subject: this.bulletinboard.title,
              email: this.email,
              message: this.message,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.bulletinformsend = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style></style>
