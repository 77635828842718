<template>
  <section class="hero-section" v-if="calltoaction">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-md-8 align-middle">
          <h2 class="text-white">{{ calltoaction.title }}</h2>
          <div class="h-100 text-white" v-html="calltoaction.description"></div>
        </div>
        <div class="col-md-4">
          <div class="d-grid gap-2">
            <a href="#contactsection">
              <button class="btn btn-primary btn-lg p-4">
                <strong class="p-4">{{ calltoaction.btn_text }}</strong>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "CTASection",
  components: {},
  data() {
    return {
      calltoaction: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/calltoaction", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.calltoaction = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #db7257;
  color: #263540;
}
.hero-container {
  padding: 2em;
}
</style>
