<template>
  <section id="contactsection" class="hero-section">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="ratio ratio-16x9">
            <img
              class="image"
              src="https://db.winnenindelft.nl/storage/uploads/2024/07/03/container_uid_66855db357d96.jpg"
              width="100%"
            />
          </div>
        </div>
        <div class="col-md-6 align-middle">
          <h2 class="text-white">Contact</h2>
          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="E-mailadres"
            />
          </div>
          <div class="mb-3">
            <textarea
              v-model="message"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Jouw vraag"
            ></textarea>
          </div>
          <div class="d-grid gap-2">
            <button
              :disabled="isDisabled == true"
              class="btn btn-lg btn-primary"
              type="button"
              @click="postToForm()"
            >
              Verstuur
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactSection",
  components: {},
  data: () => ({
    message: null,
    email: null,
  }),
  created() {},
  computed: {
    isDisabled() {
      if (this.message && this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    postToForm() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          cockpiturl + "/inbox/submit/f69daa449521da3adab950c48d3c66435313809e",
          {
            data: {
              email: this.email,
              message: this.message,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.calltoaction = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #db7257;
  color: #263540;
}
.hero-container {
  padding: 2em;
}
input,
textarea {
  background-color: #f5f3ea;
  border-radius: 0;
}
.image {
  border-radius: 10px;
}
img {
  object-fit: cover;
}
</style>
