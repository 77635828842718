<template>
  <section id="founderssection" class="hero-section" v-if="foundersSection">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-12 align-middle">
          <h2 class="pb-2">{{ foundersSection.title }}</h2>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-md-4 g-4">
        <div
          class="col text-center"
          v-for="founder in foundersSection.founder"
          v-bind:key="founder"
        >
          <div class="card h-100">
            <div class="card-body justify-content-center align-middle">
              <div class="p-2 h-100">
                <a
                  :href="founder.url"
                  target="_blank"
                  class="align-middle h-100 in-middle"
                >
                  <img :src="getImageUrl(founder.image.path)" width="175" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "FoundersSection",
  components: {},
  data() {
    return {
      foundersSection: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/founders", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.foundersSection = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #f5f3ea;
  color: #263540;
}
.hero-container {
  padding: 2em;
}
.in-middle {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}
</style>
